import "../scss/main.scss";
import 'slick-carousel';

$(function(){
    var adminBar;
    if( $('#wpadminbar') ){ adminBar = $('#wpadminbar').innerHeight() }else{ adminBar == 0; }
    $('main').css( 'margin-top', $('#masthead').innerHeight());
    $('#breadcrumb').css( 'top', $('#masthead').innerHeight() );

    $('#up').on('click', function(){
        $('html, body').animate({scrollTop: 0}, "slow");
        return false;
    });

    $('#down').on('click', function(){
        $('html, body').animate({scrollTop: $('h1').offset().top - $('#masthead').innerHeight()*2}, "slow");
        return false;
    });

    $('#btNav').on('click', function(){
        $(this).toggleClass('open');
        $('.col--navigation-mobile').toggleClass('open');
    });

    $('#menu-item-259').on('click', function(){
        $('.site-search').toggleClass('active');
    });

    actionsScroll();
    $(window).on('scroll', function(){    
        actionsScroll();
    });

    $('.gform_button').prepend('<div class="underarrow"></div>').append(' <i class="fa-solid fa-arrow-right-long">');

    // INIT ALL FUNCTIONS
    imgBack();
    sliders();
    if( $("#table-nbperson").length != 0){ selectDynamic(); }
    if( $('.bloc--points').length != 0 || $('.tabs').length != 0) { accordeon(); }
    if( $('.tabs').length != 0){ onglets(); }
    animationScroll();

});

function actionsScroll(){
    $('.site-search').removeClass('active');

    var scrolled = $(window).scrollTop();

    if(scrolled > 0 ){
        $('body').addClass('scrolling');
    } else {
        $('body').removeClass('scrolling');
    }
    
    if( $(window).innerWidth() > 1024 ){
        if(scrolled < $('.bloc--footer-encarts').offset().top - $(window).innerHeight() && scrolled > $('#masthead').innerHeight() ){
            $('#btContact').removeClass('disappear');
        }else if(scrolled > $('.bloc--footer-encarts').offset().top - $(window).innerHeight() ){
            $('#btContact').addClass('disappear');
        }
    }
}

function imgBack(){
    $('.backImg').each(function(){
        var urlImgCurrent = $(this).children('img').attr('src');
        $(this).attr('style', 'background-image:url('+urlImgCurrent+'); background-position: 50% 50%; background-size: cover; background-repeat: no-repeat;');
        $(this).find('img').remove();			
    });

    $('.menuBackImg .widget_media_image').each(function(){
        var urlImgCurrent = $(this).children('img').attr('src');
        $(this).attr('style', 'background-image:url('+urlImgCurrent+'); background-position: 50% 50%; background-size: cover; background-repeat: no-repeat;');
        $(this).find('img').remove();			
    });
}

function sliders(){

    $('.hero--slider-wrapper').on('init', function(event, slick){
        var dots = $( '.slick-dots li' );
        dots.each( function( k, v){
          $(this).find( 'button' ).addClass( 'heading-'+ k );
        });
        var items = slick.$slides;
        items.each( function( k, v){
            var text = $(this).find('.hero--title').text()
            $( '.heading-' + k ).html('<div class="hero--title">'+text+'</div>');
            $(this).find('.hero--title').remove()
        });
      });

    $('.hero--slider-wrapper').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
        arrows: false,
        responsive: true,
        dots: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        infinite: true
    });
}

function onglets(){
    var hauteurMax = 0;
    $('.tab-content').each(function(){
        if( $(this).innerHeight() >= hauteurMax ){
            hauteurMax = $(this).innerHeight();
            $('.tab-content').css('min-height', hauteurMax);
        }
    });

    $('.tabs .underline').css({
        'width' : $('.tabs .active').innerWidth(),
        'left' : $('.tabs .active').position().left,
        'opacity' : 1
    });

    $('.tabs .tab').on('click', function(e){
        e.preventDefault();

        if( !$(this).parent('li').hasClass('active') ){
            var tab = $(this).attr('data');

            /* On suprime la class active de tous les contenus */
            $('.tab-content').removeClass('active');
            $('.tab-content').hide().delay( 200 );

            /* On supprime la classe pour tous les liens */
            $('.tabs li').removeClass('active');

            /* On ajoute la classe active à l'onglet qui doit être visible */
            $(tab).addClass('active');
            $(tab).show();

            /* On ajoute la classe pour le lien sur lequel l'internaute vient de cliquer */
            $(this).parent('li').addClass('active');

            $('.tabs .underline').css({
                'width' : $('.tabs .active').innerWidth(),
                'left' : $('.tabs .active').position().left,
                'opacity' : 1
            });

            return false;
        }
    });
}

function selectDynamic(){
    $("#table-nbperson").on('change', function(){
        var valeur = $(this).val();
        $(".table").hide();
        $("#table-"+valeur).fadeIn();
    });
}

function accordeon(){
    $('.bloc--points .point--contenu').hide();

    // On sélectionne tous les items de liste portant la classe "toggleSubMenu"
    // et on remplace l'élément span qu'ils contiennent par un lien :
    $(".points .point--intitule").on('click', function () {
        // Si le sous-menu était déjà ouvert, on le referme :
        if ($(this).next(".point--contenu:visible").length != 0) {
            $(this).next(".point--contenu").slideUp("normal");
            $(this).removeClass('active');
        }
        // Si le sous-menu est caché, on ferme les autres et on l'affiche :
        else {
            $(".points .point--contenu").slideUp("normal");
            $(".points .point--intitule").removeClass('active');
            $(this).next(".point--contenu").slideDown("normal");
            $(this).addClass('active');
        }
        // On empêche le navigateur de suivre le lien :
        return false;
    });

    if( $(window).innerWidth() <= 1024 ){
        $('.tabs span').hide();
        $('.tabs .tab-content:eq(0) span').slideDown("normal");

        // On sélectionne tous les items de liste portant la classe "toggleSubMenu"
        // et on remplace l'élément span qu'ils contiennent par un lien :
        $(".tabs-content .tab-mobile").on('click', function () {
            // Si le sous-menu était déjà ouvert, on le referme :
            if ($(this).next("span:visible").length != 0) {
                $(this).next("span").slideUp("normal");
                $(this).parent('tab-content').removeClass('active');
            }
            // Si le sous-menu est caché, on ferme les autres et on l'affiche :
            else {
                $(".tabs-content span").slideUp("normal");
                $(".tabs-content .tab-content").removeClass('active');
                $(this).next("span").slideDown("normal");
                $(this).parent('.tab-content').addClass('active');
            }
            // On empêche le navigateur de suivre le lien :
            return false;
        });

        $('#nav-mobile > li.menu-item > ul.sub-menu > li.menu-item > ul.sub-menu').hide();

        // On sélectionne tous les items de liste portant la classe "toggleSubMenu"
        // et on remplace l'élément span qu'ils contiennent par un lien :
        $("#nav-mobile > li.menu-item > ul.sub-menu > li.menu-item > a").on('click', function () {
            // Si le sous-menu était déjà ouvert, on le referme :
            if ($(this).next("ul.sub-menu:visible").length != 0) {
                $(this).next("ul.sub-menu").slideUp("normal");
                $(this).parent('li.menu-item').removeClass('active');
            }
            // Si le sous-menu est caché, on ferme les autres et on l'affiche :
            else {
                $("#nav-mobile > li.menu-item > ul.sub-menu > li.menu-item > ul.sub-menu").slideUp("normal");
                $("#nav-mobile > li.menu-item > ul.sub-menu > li.menu-item").removeClass('active');
                $(this).next("ul.sub-menu").slideDown("normal");
                $(this).parent('li.menu-item').addClass('active');
            }            
        });
    }
}

function animationScroll(){
    gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);

    ScrollTrigger.defaults({
        toggleActions: "restart pause resume pause"
    });

    let scrollAnimFade = gsap.utils.toArray('.elem--anim-opacity');
    scrollAnimFade.forEach(item => {
        gsap.from(item, {
            scrollTrigger: {
                trigger: item,
                start: "top 90%",
                end: "top 75%",
                markers: false,
                scrub: true
            },
            opacity: 0,
            duration: 0.5
        });
    });
 
    let scrollAnimTitle = gsap.utils.toArray('.title-anim');
    scrollAnimTitle.forEach(item => {
        gsap.from(item, {
            scrollTrigger: {
                trigger: item,
                start: "top 90%",
                end: "top 75%",
                markers: false,
                scrub: true
            },
            opacity: 0,
            duration: 0.5
        });
    });

    let scrollAnimtop = gsap.utils.toArray('.elem--anim-top');
    scrollAnimtop.forEach(item => {
        gsap.from(item, {
            scrollTrigger: {
                trigger: item,
                start: "top 90%",
                end: "top 75%",
                markers: false,
                scrub: true
            },
            opacity: 0,
            y: 90,
            duration: 0.5
        });
    });    

    gsap.to(".elem--anim-enter", 0.5, { autoAlpha: 1, y: 0 });

    if( $(".bloc--steps").length != 0 ){
        var size = $('.step:eq(2)').offset().top - $('.step:eq(1)').offset().top;
        $('.step--svg.round').css('width', size).css('height', size);

        let steps = gsap.timeline({
            scrollTrigger: {
                trigger: ".step",
                start: "top 75%",
                end: "top top",
                markers: false,
                scrub: true
            }
        });
        steps.staggerFrom(".step", 0.5, { autoAlpha:0, scale:0.5 }, 0.5, "#begin")
        .staggerFrom(".step--svg.inline", 0.2, { width: 0 }, 0.5, "#begin+=0.5");

        gsap.from(".right .round_circle", {
            drawSVG: "0%",
            ease: "none",
            scrollTrigger: {
                trigger: ".right .round_circle",
                start: "center 75%",
                end: "center 65%",
                markers: false,
                scrub: true
            }
        });

        gsap.from(".left .round_circle", {
            drawSVG: "0%",
            ease: "none",
            delay:0.7,
            scrollTrigger: {
                trigger: ".left .round_circle",
                start: "center 75%",
                end: "bottom 65%",
                markers: false,
                scrub: true
            }
        });
    }
}